// SET global classes.
.ui- {
  &-app {
    &-container {
      @apply relative;

      &::before {
        @apply pointer-events-none absolute inset-x-0 top-0 z-0 aspect-video -translate-y-1/2;

        content: '';
        background-image: radial-gradient(closest-side, #245fef 0%, transparent 100%);
      }
    }
  }

  &-router {
    &-view {
      @apply px-4 pb-[100px] max-sm:px-2;
    }
  }

  &-page {
    &-message {
      @apply my-4 flex h-10 select-none items-center overflow-x-hidden rounded-sm bg-black/50;
    }
  }
}
