@layer components {
  .ui--input {
    &-provider {
      @apply relative;
    }

    &-label {
      @apply mb-1 inline-flex items-center leading-4;

      &.required::after {
        @apply ml-1 text-base text-rose-600;
        content: ' *';
      }

      .text {
        @apply text-sm text-gray-400;
      }
    }

    &-icon {
      @apply absolute left-0 flex size-12 items-center justify-center text-black;

      & ~ input {
        padding-left: 48px !important;
      }
    }

    &-field {
      @apply relative flex;

      input {
        @apply block h-12 rounded-lg bg-black/5 px-4 text-black ring-1 ring-black/10 transition-all;

        &:focus {
          @apply bg-black/10 ring-black/20;
        }

        &::placeholder {
          @apply text-sm font-normal tracking-normal text-black/20;
        }

        & ~ .icon {
          @apply absolute bottom-[1px] right-0 flex h-11 w-8 items-center justify-center text-black;

          &.is-text {
            @apply text-neutral-100;
          }
        }

        @include attr('type', 'password') {
          @apply font-bold tracking-[2px];
        }

        @include attr('type', ('number', 'search')) {
          @apply appearance-none;
          -moz-appearance: textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            @apply appearance-none;
          }
        }
      }
    }

    &-select {
      @apply relative grid h-12 w-full items-center gap-1 rounded-lg bg-black/5 px-4 ring-1 ring-black/10;
      grid-template-columns: 1fr auto;

      & > .icon {
        @apply absolute left-0 flex size-12 items-center justify-center text-black;

        & ~ .text {
          padding-left: 32px !important;
        }
      }

      & > .text {
        &:not(.selected) {
          @apply text-sm font-normal tracking-normal text-black/30;
        }
      }

      .text-2 {
        color: black !important;
      }

      & > .bi {
        @apply text-black;
      }
    }

    &-errors {
      @apply block text-xs font-normal italic text-rose-600;
    }
  }

  .ui--select {
    @apply relative;

    &-field {
      @apply grid h-12 cursor-pointer grid-flow-col items-center gap-2 rounded-lg bg-black/20 px-4 ring-1 ring-white/10;

      .icon {
        @apply flex size-12 items-center justify-center;

        & ~ .input {
          padding-left: 48px !important;
        }
      }
    }

    &-dropdown {
      @apply absolute top-full z-10 grid min-w-36 gap-1 rounded-lg bg-white p-1 ring-0 drop-shadow transition-all;

      &:not(.active) {
        @apply pointer-events-none opacity-0;
        transform: translateY(-4px);
      }

      .li {
        @apply flex h-8 cursor-pointer items-center justify-start gap-2 px-2;

        &:hover {
          @apply rounded-lg;
          background-color: #f1f2f9;
        }

        &.selected {
          &::before {
            @apply block size-2 rounded-full bg-theme;
            content: '';
          }
        }
      }
    }
  }
}
