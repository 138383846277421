html,
body {
  @apply scroll-smooth bg-theme-back font-primary text-white antialiased;
}

* {
  &::-webkit-scrollbar {
    @apply size-2;
  }

  &::-webkit-scrollbar-track {
    @apply bg-theme-back;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded-sm border-x-2 border-y-0 border-solid border-theme-back bg-theme;
  }
}

a {
  @apply inline-block cursor-pointer select-none text-inherit no-underline outline-none;
}

img {
  @apply pointer-events-none select-none;
}

label {
  @apply select-none;
}

button,
input,
select,
textarea {
  @apply border-none bg-transparent font-primary shadow-none outline-none first-line:text-inherit;
}

button {
  @apply select-none;
}

input,
select,
textarea {
  @apply w-full;
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    @apply m-0 appearance-none;
  }

  &[type='number'] {
    appearance: textfield;
  }
}
