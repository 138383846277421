@layer components {
  .ui--pagination {
    &-result,
    &-render {
      @apply my-4 flex w-full items-center justify-center gap-2;
    }

    &-render {
      .buttonPage {
        @apply h-8 w-8 cursor-pointer rounded-lg bg-[#0000004d] text-base font-bold text-white;

        &:disabled {
          @apply cursor-not-allowed;
        }
      }

      .next,
      .prev {
        @apply flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg text-lg font-bold;

        color: #fff;
        background-image: linear-gradient(to left, #5386f9 0%, #104bdc 100%);

        &:disabled {
          @apply cursor-not-allowed bg-[#0000004d] bg-none;
        }
      }

      .active {
        color: #fff;
        background-image: linear-gradient(to left, #5386f9 0%, #104bdc 100%);
      }
    }

    &-result {
      @apply py-3;
    }
  }
}
