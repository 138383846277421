.ui--account {
  @apply pt-8;

  input {
    @apply bg-white/5 px-4 text-white ring-1 ring-white/20;

    &::placeholder {
      @apply text-white/25;
    }

    &:hover {
      @apply bg-white/10 ring-white/25;
    }

    & ~ .icon {
      @apply text-white;
    }
  }

  .ui--input-icon {
    @apply text-white;
  }

  &.info {
  }

  &.affiliate {
  }

  &.redeem {
  }

  &.pwd {
  }

  &-container {
    &.context {
      @apply mx-auto max-w-2xl rounded-2xl bg-theme-dark/40 p-6 max-sm:p-4;
    }

    .btn {
      &-grid {
        @apply grid-flow-row gap-2 px-4 text-center max-sm:px-2;
      }
    }
  }
}
